/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    @media (min-width: 768px) {
        .slick-caption {
            display: block !important;
        }
    }
    .slick-caption {
        padding-bottom: 45px;
        position: absolute;
        right: 15%;
        bottom: 20px;
        left: 15%;
        z-index: 10;
        padding-top: 20px;
        color: #ffffff;
        text-align: center;
        z-index: 3;
        display: none;
    }
    .slick-slide {
        height: 100%;
        >div:first-child {
            position: relative;
        }
    }
    .slick-icons {
        position: relative;
        top: 5px;
    }
    .slick-image {
        width: 100% !important;
        display: inline-flex !important;
    }
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
    border: 1px solid rgba(0, 0, 0, 0.7);
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 64px;
    overflow: hidden;
    padding: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
    color: transparent;
    outline: none;
    z-index: 2;
    opacity: 0.5;
    &:hover {
        background-color: #00b74a;
        border: 1px solid #00b74a;
    }
}

.slick-prev {
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-image: url("/static/icons/prev.png");
}

.slick-next {
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-image: url("/static/icons/next.png");
}

.slick-list {
    z-index: 1;
}

.slick-dots {
    margin-top: 0;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 5px;
    // margin-right: 15%;
    // margin-left: 15%;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
    z-index: 3;
}

.slick-dots li,
.slick-dots li button {
    width: 32px;
    height: 6px;
    cursor: pointer;
}

// .color{
//     color:rgba(3, 143, 59);
//     color:rgb(240, 124, 104);
//     color:rgb(48, 17, 224);
//     color:rgb(235, 214, 30);
//     color:rgb(8, 180, 37);
//     color:rgb(113, 163, 230);
//     color:rgb(245, 165, 16);
// }
.slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 5px;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 6px;
        content: "\2022";
        text-align: center;
        opacity: 1;
        background: rgba(255, 255, 255, 0.6);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        transition: all 300ms linear;
    }
}

.slick-dots li.slick-active button:before {
    background: rgba(255, 255, 255, 1);
    // box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.slick-slider.rounddot-slick {
    .slick-dots li,
    .slick-dots li button {
        width: 8px;
        height: 8px;
    }
    .slick-dots li {
        margin: 0 3px;
    }
    .slick-dots li button {
        &::before {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgba(125, 125, 125, 0.6);
        }
    }
    .slick-dots {
        bottom: -5px;
    }
    .slick-dots li.slick-active button:before {
        background: rgb(255, 167, 4);
    }
}

.slick-slider.gray-slick {
    button.slick-arrow.slick-prev {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    button.slick-arrow.slick-next {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .slick-dots li,
    .slick-dots li button {
        width: 8px;
        height: 8px;
    }
    .slick-dots li {
        margin: 0 3px;
    }
    .slick-dots li button {
        &::before {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgba(125, 125, 125, 0.6);
        }
    }
    .slick-dots {
        margin-top: 0;
        margin-bottom: 1rem;
        position: static;
    }
    .slick-dots li.slick-active button:before {
        background: rgb(255, 167, 4);
    }
}

.slick-slider.vertical {
    button.slick-arrow.slick-prev {
        border-top-right-radius: 0;
        left: auto;
        right: 16px;
        top: -16px;
        margin: 0;
        transform: rotate(90deg)
    }
    button.slick-arrow.slick-next {
        border-top-left-radius: 0;
        left: auto;
        right: 16px;
        top: auto;
        bottom: -32px;
        margin: 0;
        transform: rotate(90deg)
    }
    .slick-dots {
        display: none
    }
}

.slick-slider.vertical.small {
    button.slick-arrow.slick-prev {
        display: none
    }
    button.slick-arrow.slick-next {
        display: none
    }
}



.slick-slider.gallery-slick {
    button.slick-arrow.slick-prev,
    button.slick-arrow.slick-next {
        background-color: #f3f3f3;
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        display: block;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 100%;
        overflow: hidden;
        padding: 0;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        cursor: pointer;
        border: none;
        color: transparent;
        outline: none;
        z-index: 2;
        opacity: 0.5;
        &:hover {
            background-color: #ff6600;
        }
    }

    button.slick-arrow.slick-prev {
        border-radius: 0;
        left: -26px;
        right: auto;
        margin: 0;
        height: 100%;
         background-image: url("../images/prev-yellow.png");
        &:hover {
            background-image: url("../images/prev.png");
        }
    }
    button.slick-arrow.slick-next {
        border-radius: 0;
        height: 100%;
        left: auto;
        right: -26px;
        margin: 0;
        background-image: url("../images/next-yellow.png");
        &:hover {
            background-image: url("../images/next.png");
        }
    }
    .slick-dots li,
    .slick-dots li button {
        width: 8px;
        height: 8px;
    }
    .slick-dots li {
        margin: 0 3px;
    }
    .slick-dots li button {
        &::before {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgba(125, 125, 125, 0.6);
        }
    }
    .slick-dots {
        margin-top: 0;
        margin-bottom: 1rem;
        position: static;
    }
    .slick-dots li.slick-active button:before {
        background: rgb(255, 167, 4);
    }
}
