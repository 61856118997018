.layout {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    &-header {
        z-index: 99;
        font-size: 2vh;
        &__inner {
            display: flex;
            flex-direction: row;
            &__left {
                float: left;
                align-items: center;
                justify-content: center;
                span {
                    float: left;
                    line-height: 60px;
                }
            }
            &__navbar {
                flex: 1;
            }
        }
    }
    .screen {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    }
    .screenMain {
        display: "flex";
        flex-direction: "column";
    }
}

.footer {
    height: 25px;
    overflow: hidden;
    padding: 0 8px;
    color: #888;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    bottom: 0;
    font-size: 8px;
}

.grow {
    flex-grow: 1;
}