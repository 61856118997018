.page-title {
  padding: 10px;
  // box-shadow: 0px 0 4vh 4px #7d7d7d;
}
.page-toolbar {
  display: flex;
  padding: 10px;
  // box-shadow: 0px 0 4vh 4px #7d7d7d;
  button {
    margin-right: 10px;
    align-items: center;
  }
  .boolbar-icon {
    padding-top: 5px;
    padding-right: 3px;
  }
}

.page-form {
  padding: 0;
  input {
    font-size: 14px;
  }
  &-field {
    padding-bottom: 10px;
  }
  .MuiFormControlLabel-label {
    color: #888;
    text-align: right;
  }
}
.page-form.normal {
  padding: 10px;
}
.page-content {
  padding: 10px;
}
