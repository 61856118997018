@import "./List.scss";
.block--code {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: "Inconsolata", "Menlo", "Consolas", monospace;
    font-size: 16;
    padding: 3px 5px;
    border-radius: 5px;
}

.block--blockquote {
    border-left: 5px solid #f1f1f1;
    padding-left: 5px;
}

.block-text-align--right {
    text-align: right;
}

.block-text-align--center {
    text-align: center;
}

.block-text-align--left {
    text-align: left;
}

.block--code {
    max-width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 3px 5px;
    border-radius: 5;
    font-size: 14px;
    font-family: Verdana;
}

.block--blockquote {
    max-width: 100%;
    border-left: 3px solid #eee;
    padding: 3px 5px;
}

.block--link {
    color: blue;
    text-decoration: underline;
}

.toolbar-button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    min-height: 32px;
    margin: 0 5px 5px 0;
    font-size: 14px;
    border: 1px solid #f3f3f3;
}

.toolbar-button-toogle-button {
    margin: 0;
    border: 0;
}

.option-editor-modal {
    padding: 0;
}

.editor-option-item {
    display: block;
    padding: 3px 20px 3px 10px;
    margin: 0;
    cursor: pointer;
    &:hover {
        background: #f0f0f0;
    }
    h1 {
        margin: 0;
        padding: 0;
    }
    h2 {
        margin: 0;
        padding: 0;
    }
    h3 {
        margin: 0;
        padding: 0;
    }
    h4 {
        margin: 0;
        padding: 0;
    }
    h5 {
        margin: 0;
        padding: 0;
    }
    h6 {
        margin: 0;
        padding: 0;
    }
    p {
        margin: 0;
        padding: 0;
    }
}

.editor-option-item.selected {
    color: #aaa;
    background: #fcfcfc;
}