@charset "UTF-8";
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 0.24s;
  -webkit-animation-duration: 0.24s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut;
  -webkit-animation-name: fadeOut;
  animation-duration: 0.24s;
  -webkit-animation-duration: 0.24s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

html,
body,
#app {
  margin: 0px;
  padding: 0;
  font-size: 14px; }

span,
div,
th,
td,
ul,
li,
ol,
input,
a {
  font-family: "微软雅黑", "宋体", Verdana, Geneva, Tahoma, sans-serif, Arial;
  font-size: 14px; }

.table-xls-download {
  margin: 0 8px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 6px 8px;
  font-size: 12px; }
  .table-xls-download svg {
    margin: 0 4px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input[type="number"] {
  -moz-appearance: textfield; }

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset; }

#app {
  position: fixed;
  width: 100%;
  height: 100%; }

.app-logo {
  width: 42px;
  height: 32px;
  margin-left: 8px; }

* {
  outline: none; }

figure {
  margin: 0;
  padding: 0; }

.fade-enter {
  opacity: 0;
  z-index: 1; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in; }

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(125, 125, 125, 0.1);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 999;
  top: 0;
  left: 0; }

.loading-inner {
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #dedede;
  box-shadow: 0px 0 4vh 4px #dedede;
  margin: 0 auto;
  padding: 5px 10px; }

.error {
  padding: 30px 0 20px 0;
  display: flex;
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center; }

.error-icon {
  margin: 10px; }

.mini-icon {
  width: 16px;
  height: 16px; }

figure {
  display: flex;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0; }

.date-picker-form-control {
  padding: 8px 10px;
  font-size: 14px;
  width: 180px;
  transition: none;
  height: auto;
  line-height: auto;
  border: 1px solid #dedede;
  border-radius: 4px;
  color: #555; }

.public-DraftEditorPlaceholder-inner {
  color: "#aaa"; }

.layout {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .layout-header {
    z-index: 99;
    font-size: 2vh; }
    .layout-header__inner {
      display: flex;
      flex-direction: row; }
      .layout-header__inner__left {
        float: left;
        align-items: center;
        justify-content: center; }
        .layout-header__inner__left span {
          float: left;
          line-height: 60px; }
      .layout-header__inner__navbar {
        flex: 1; }
  .layout .screen {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden; }
  .layout .screenMain {
    display: "flex";
    flex-direction: "column"; }

.footer {
  height: 25px;
  overflow: hidden;
  padding: 0 8px;
  color: #888;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  bottom: 0;
  font-size: 8px; }

.grow {
  flex-grow: 1; }

.page-title {
  padding: 10px; }

.page-toolbar {
  display: flex;
  padding: 10px; }
  .page-toolbar button {
    margin-right: 10px;
    align-items: center; }
  .page-toolbar .boolbar-icon {
    padding-top: 5px;
    padding-right: 3px; }

.page-form {
  padding: 0; }
  .page-form input {
    font-size: 14px; }
  .page-form-field {
    padding-bottom: 10px; }
  .page-form .MuiFormControlLabel-label {
    color: #888;
    text-align: right; }

.page-form.normal {
  padding: 10px; }

.page-content {
  padding: 10px; }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(11, 11, 11, 0.4);
  width: 100%; }
  .modal-overlay .modal {
    position: fixed;
    top: calc(100% - 260px);
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1001;
    background-color: #fff;
    width: 100%;
    height: 260px; }

.modal-transition-enter {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 0.24s;
  -webkit-animation-duration: 0.24s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

.modal-transition-leave {
  animation-name: fadeOut;
  -webkit-animation-name: fadeOut;
  animation-duration: 0.24s;
  -webkit-animation-duration: 0.24s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

.modal-transition-enter .modal {
  -webkit-animation-name: transitionBottomToUp-260;
  animation-name: transitionBottomToUp-260;
  animation-duration: 0.24s;
  -webkit-animation-duration: 0.24s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  animation-duration: 0.2s;
  -webkit-animation-duration: 0.2s; }

@keyframes transitionBottomToUp-260 {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    -webkit-transform: translate3d(0, calc(100% - 260px), 0);
    transform: translate3d(0, calc(100% - 260px), 0); } }

@-webkit-keyframes transitionBottomToUp-260 {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    -webkit-transform: translate3d(0, calc(100% - 260px), 0);
    transform: translate3d(0, calc(100% - 260px), 0); } }

.modal-transition-leave .modal {
  animation-name: transitionUpToBottom-260;
  -webkit-animation-name: transitionUpToBottom-260;
  animation-duration: 0.24s;
  -webkit-animation-duration: 0.24s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  animation-duration: 0.2s;
  -webkit-animation-duration: 0.2s; }

@keyframes transitionUpToBottom-260 {
  0% {
    top: calc(100% - 260px);
    -webkit-transform: translate3d(0, calc(100% - 260px), 0);
    transform: translate3d(0, calc(100% - 260px), 0); }
  100% {
    top: 100%;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@-webkit-keyframes transitionUpToBottom-260 {
  0% {
    top: calc(100% - 260px);
    -webkit-transform: translate3d(0, calc(100% - 260px), 0);
    transform: translate3d(0, calc(100% - 260px), 0); }
  100% {
    top: 100%;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }
  @media (min-width: 768px) {
    .slick-slider .slick-caption {
      display: block !important; } }
  .slick-slider .slick-caption {
    padding-bottom: 45px;
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    color: #ffffff;
    text-align: center;
    z-index: 3;
    display: none; }
  .slick-slider .slick-slide {
    height: 100%; }
    .slick-slider .slick-slide > div:first-child {
      position: relative; }
  .slick-slider .slick-icons {
    position: relative;
    top: 5px; }
  .slick-slider .slick-image {
    width: 100% !important;
    display: inline-flex !important; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ""; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  border: 1px solid rgba(0, 0, 0, 0.7);
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 64px;
  overflow: hidden;
  padding: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  color: transparent;
  outline: none;
  z-index: 2;
  opacity: 0.5; }
  button.slick-arrow.slick-prev:hover,
  button.slick-arrow.slick-next:hover {
    background-color: #00b74a;
    border: 1px solid #00b74a; }

.slick-prev {
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-image: url("/static/icons/prev.png"); }

.slick-next {
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-image: url("/static/icons/next.png"); }

.slick-list {
  z-index: 1; }

.slick-dots {
  margin-top: 0;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 5px;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  z-index: 3; }

.slick-dots li,
.slick-dots li button {
  width: 32px;
  height: 6px;
  cursor: pointer; }

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }
  .slick-dots li button::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 6px;
    content: "\2022";
    text-align: center;
    opacity: 1;
    background: rgba(255, 255, 255, 0.6);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 300ms linear; }

.slick-dots li.slick-active button:before {
  background: white; }

.slick-slider.rounddot-slick .slick-dots li,
.slick-slider.rounddot-slick .slick-dots li button {
  width: 8px;
  height: 8px; }

.slick-slider.rounddot-slick .slick-dots li {
  margin: 0 3px; }

.slick-slider.rounddot-slick .slick-dots li button::before {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(125, 125, 125, 0.6); }

.slick-slider.rounddot-slick .slick-dots {
  bottom: -5px; }

.slick-slider.rounddot-slick .slick-dots li.slick-active button:before {
  background: #ffa704; }

.slick-slider.gray-slick button.slick-arrow.slick-prev {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; }

.slick-slider.gray-slick button.slick-arrow.slick-next {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; }

.slick-slider.gray-slick .slick-dots li,
.slick-slider.gray-slick .slick-dots li button {
  width: 8px;
  height: 8px; }

.slick-slider.gray-slick .slick-dots li {
  margin: 0 3px; }

.slick-slider.gray-slick .slick-dots li button::before {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(125, 125, 125, 0.6); }

.slick-slider.gray-slick .slick-dots {
  margin-top: 0;
  margin-bottom: 1rem;
  position: static; }

.slick-slider.gray-slick .slick-dots li.slick-active button:before {
  background: #ffa704; }

.slick-slider.vertical button.slick-arrow.slick-prev {
  border-top-right-radius: 0;
  left: auto;
  right: 16px;
  top: -16px;
  margin: 0;
  transform: rotate(90deg); }

.slick-slider.vertical button.slick-arrow.slick-next {
  border-top-left-radius: 0;
  left: auto;
  right: 16px;
  top: auto;
  bottom: -32px;
  margin: 0;
  transform: rotate(90deg); }

.slick-slider.vertical .slick-dots {
  display: none; }

.slick-slider.vertical.small button.slick-arrow.slick-prev {
  display: none; }

.slick-slider.vertical.small button.slick-arrow.slick-next {
  display: none; }

.slick-slider.gallery-slick button.slick-arrow.slick-prev,
.slick-slider.gallery-slick button.slick-arrow.slick-next {
  background-color: #f3f3f3;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 100%;
  overflow: hidden;
  padding: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  color: transparent;
  outline: none;
  z-index: 2;
  opacity: 0.5; }
  .slick-slider.gallery-slick button.slick-arrow.slick-prev:hover,
  .slick-slider.gallery-slick button.slick-arrow.slick-next:hover {
    background-color: #ff6600; }

.slick-slider.gallery-slick button.slick-arrow.slick-prev {
  border-radius: 0;
  left: -26px;
  right: auto;
  margin: 0;
  height: 100%;
  background-image: url("../images/prev-yellow.png"); }
  .slick-slider.gallery-slick button.slick-arrow.slick-prev:hover {
    background-image: url("../images/prev.png"); }

.slick-slider.gallery-slick button.slick-arrow.slick-next {
  border-radius: 0;
  height: 100%;
  left: auto;
  right: -26px;
  margin: 0;
  background-image: url("../images/next-yellow.png"); }
  .slick-slider.gallery-slick button.slick-arrow.slick-next:hover {
    background-image: url("../images/next.png"); }

.slick-slider.gallery-slick .slick-dots li,
.slick-slider.gallery-slick .slick-dots li button {
  width: 8px;
  height: 8px; }

.slick-slider.gallery-slick .slick-dots li {
  margin: 0 3px; }

.slick-slider.gallery-slick .slick-dots li button::before {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(125, 125, 125, 0.6); }

.slick-slider.gallery-slick .slick-dots {
  margin-top: 0;
  margin-bottom: 1rem;
  position: static; }

.slick-slider.gallery-slick .slick-dots li.slick-active button:before {
  background: #ffa704; }

.image-gallery-icon {
  color: #fff;
  transition: all .2s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a); }
  @media (min-width: 768px) {
    .image-gallery-icon:hover {
      color: #337ab7; }
      .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1); } }
  .image-gallery-icon:focus {
    outline: 2px solid #337ab7; }

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none; }

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px; }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 22px;
    width: 22px; }
  @media (max-width: 768px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 15px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px; } }
  @media (max-width: 480px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 10px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px; } }

.image-gallery-fullscreen-button {
  right: 0; }

.image-gallery-play-button {
  left: 0;
  display: none; }

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%); }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 44px;
    width: 22px; }
  @media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 72px;
      width: 36px; } }
  @media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 48px;
      width: 24px; } }
  .image-gallery-left-nav[disabled],
  .image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none; }

.image-gallery-left-nav {
  left: 0; }

.image-gallery-right-nav {
  right: 0; }

.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative; }
  .image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5; }
    .image-gallery.fullscreen-modal .image-gallery-content {
      top: 50%;
      transform: translateY(-50%); }

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0; }
  .image-gallery-content.fullscreen {
    background: #000; }
  .image-gallery-content .image-gallery-slide img {
    width: 100%;
    height: 400px; }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px); }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 100vh; }

.image-gallery-slide-wrapper {
  position: relative; }
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 110px); }
    @media (max-width: 768px) {
      .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
        width: calc(100% - 87px); } }
  .image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl; }

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center; }

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .image-gallery-slide.center {
    position: relative; }
  .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain; }
  .image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal; }
    @media (max-width: 768px) {
      .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: .8em;
        padding: 8px 15px; } }

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4; }
  .image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center; }
  .image-gallery-bullets .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 0 #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: background .2s ease-out; }
    @media (max-width: 768px) {
      .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px; } }
    @media (max-width: 480px) {
      .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px; } }
    .image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
      background: #337ab7;
      transform: scale(1.1); }
    .image-gallery-bullets .image-gallery-bullet.active {
      background: #fff; }

.image-gallery-thumbnails-wrapper {
  position: relative; }
  .image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 100px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        width: 81px; } }
    .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0; }
      .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0; }
        .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 5px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        margin: 0 3px; } }

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnails {
      padding: 3px 0; } }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform .45s ease-out;
    white-space: nowrap; }

.image-gallery-thumbnail {
  display: inline-block;
  border: 2px solid transparent;
  transition: border .3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail {
      border: 1px solid transparent;
      width: 81px; } }
  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 2px; }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative; }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0; }
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid #ff6600; }
    @media (max-width: 768px) {
      .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
        border: 1px solid #ff6600; } }

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail-label {
      font-size: .8em;
      line-height: .8em; } }

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4; }
  @media (max-width: 768px) {
    .image-gallery-index {
      font-size: .8em;
      padding: 5px 10px; } }

.PresetDateRangePicker_panel {
  padding: 0 22px 11px; }

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer; }

.PresetDateRangePicker_button:active {
  outline: 0; }

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699; }

.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff; }

.SingleDatePickerInput__withBorder {
  border-radius: 4px;
  border: 1px solid #dbdbdb; }

.SingleDatePickerInput__rtl {
  direction: rtl; }

.SingleDatePickerInput__disabled {
  background-color: #f2f2f2; }

.SingleDatePickerInput__block {
  display: block; }

.SingleDatePickerInput__showClearDate {
  padding-right: 30px; }

.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%; }

.SingleDatePickerInput_clearDate__small {
  padding: 6px; }

.SingleDatePickerInput_clearDate__hide {
  visibility: hidden; }

.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle; }

.SingleDatePickerInput_clearDate_svg__small {
  height: 9px; }

.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px; }

.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle; }

.SingleDatePicker {
  position: relative;
  display: flex; }

.SingleDatePicker__block {
  display: block; }

.SingleDatePicker_picker {
  z-index: 1;
  background-color: transparent;
  position: absolute; }

.SingleDatePicker_picker__rtl {
  direction: rtl; }

.SingleDatePicker_picker__directionLeft {
  left: 0; }

.SingleDatePicker_picker__directionRight {
  right: 0; }

.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff; }

.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2; }

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none; }

.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd; }

.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px; }

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0; }

.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2; }

.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute; }

.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489; }

.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489; }

.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0; }

.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0; }

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489; }

.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute; }

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px; }

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px; }

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px; }

.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: transparent;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left; }

.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0; }

.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px; }

.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2; }

.DayPickerKeyboardShortcuts_close:active {
  outline: 0; }

.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd; }

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a; }

.CalendarDay {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center; }

.CalendarDay:active {
  outline: 0; }

.CalendarDay__defaultCursor {
  cursor: default; }

.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff; }

.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit; }

.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit; }

.CalendarDay__outside {
  border: 0;
  background: transparent;
  color: #484848; }

.CalendarDay__outside:hover {
  border: 0; }

.CalendarDay__blocked_minimum_nights {
  background: transparent;
  border: 1px solid #eceeee;
  color: #cacccd; }

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: transparent;
  color: #cacccd; }

.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848; }

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848; }

.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff; }

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff; }

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff; }

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87; }

.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87; }

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a; }

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: transparent;
  border: 1px solid #e4e7e7;
  color: #cacccd; }

.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee; }

.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7; }

.CalendarMonth {
  background: transparent;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0; }

.CalendarMonth_verticalSpacing {
  border-collapse: separate; }

.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial; }

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px; }

.CalendarMonthGrid {
  background: transparent;
  text-align: left;
  z-index: 0; }

.CalendarMonthGrid__animating {
  z-index: 1; }

.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px; }

.CalendarMonthGrid__vertical,
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto; }

.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%; }

.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none; }

.CalendarMonthGrid_month__hidden {
  visibility: hidden; }

.DayPickerNavigation {
  position: relative;
  z-index: 2; }

.DayPickerNavigation__horizontal {
  height: 0; }

.DayPickerNavigation__verticalScrollable_prevNav {
  z-index: 1; }

.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0; }

.DayPickerNavigation__verticalScrollableDefault {
  position: relative; }

.DayPickerNavigation__bottom {
  height: auto; }

.DayPickerNavigation__bottomDefault {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: inline-block;
  -webkit-justify-content: space-between;
  justify-content: space-between; }

.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0; }

.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: transparent;
  color: #757575; }

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4; }

.DayPickerNavigation_button__default:active {
  background: #f2f2f2; }

.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2; }

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2; }

.DayPickerNavigation_button__disabled:active {
  background: 0 0; }

.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: .78;
  border-radius: 3px;
  padding: 6px 9px; }

.DayPickerNavigation_bottomButton__horizontalDefault {
  position: static;
  margin: -10px 22px 30px; }

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px; }

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px; }

.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: transparent;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%; }

.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0; }

.DayPickerNavigation_nextButton__verticalScrollableDefault,
.DayPickerNavigation_prevButton__verticalScrollableDefault {
  width: 100%; }

.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block; }

.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848; }

.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2; }

.DayPicker {
  background: transparent;
  position: relative;
  text-align: left; }

.DayPicker__horizontal {
  background: #fff; }

.DayPicker__verticalScrollable {
  height: 100%; }

.DayPicker__hidden {
  visibility: hidden; }

.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px; }

.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%; }

.DayPicker_portal__vertical {
  position: initial; }

.DayPicker_focusRegion {
  outline: 0; }

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top; }

.DayPicker_weekHeaders {
  position: relative; }

.DayPicker_weekHeaders__horizontal {
  margin-left: 9px; }

.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left; }

.DayPicker_weekHeader__vertical {
  left: 50%; }

.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: transparent;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center; }

.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px; }

.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center; }

.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px; }

.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height .2s ease-in-out;
  -moz-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out; }

.DayPicker_transitionContainer__vertical {
  width: 100%; }

.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll; }

.DateInput {
  margin: 0;
  padding: 0;
  background: transparent;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle; }

.DateInput__small {
  width: 97px; }

.DateInput__block {
  width: 100%; }

.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb; }

.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: transparent;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .2px;
  padding: 7px 7px 5px; }

.DateInput_input__regular {
  font-weight: auto; }

.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.DateInput_input__focused {
  outline: 0;
  background: transparent;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0; }

.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic; }

.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2; }

.DateInput_fangShape {
  fill: #fff; }

.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent; }

.DateRangePickerInput {
  background-color: #fff;
  display: flex;
  align-items: center; }

.DateRangePickerInput__disabled {
  background: #f2f2f2; }

.DateRangePickerInput__withBorder {
  border-radius: 3px;
  border: 1px solid #dbdbdb; }

.DateRangePickerInput__rtl {
  direction: rtl; }

.DateRangePickerInput__block {
  display: block; }

.DateRangePickerInput__showClearDates {
  padding-right: 30px; }

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848;
  font-size: 22px; }

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px; }

.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.DateRangePickerInput_clearDates__small {
  padding: 6px; }

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%; }

.DateRangePickerInput_clearDates__hide {
  visibility: hidden; }

.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle; }

.DateRangePickerInput_clearDates_svg__small {
  height: 9px; }

.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px; }

.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle; }

.DateRangePicker {
  position: relative;
  display: flex; }

.DateRangePicker__block {
  display: block; }

.DateRangePicker_picker {
  z-index: 1;
  background-color: transparent;
  position: absolute; }

.DateRangePicker_picker__rtl {
  direction: rtl; }

.DateRangePicker_picker__directionLeft {
  left: 0; }

.DateRangePicker_picker__directionRight {
  right: 0; }

.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff; }

.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2; }

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none; }

.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd; }
