.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom:0;
  z-index: 1000;
  background-color:rgba(11,11,11,0.4);
  width: 100%;

  .modal {
    position: fixed;
    top: calc(100% - 260px);
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1001;
    background-color: #fff;
    width: 100%;
    height: 260px;
  }
}

.modal-transition-enter {
  @include fadeIn;
}

.modal-transition-leave {
  @include fadeOut;
}

.modal-transition-enter .modal {
  @include transitionBottomToUpFn('260',calc(100% - 260px));
}

.modal-transition-leave .modal {
  @include transitionUpToBottomFn('260',calc(100% - 260px));
}
